import React, { useState, useContext } from 'react';
import { Container } from 'react-bootstrap';
import './gallery.content.css';
import {FaChevronCircleLeft, FaChevronCircleRight} from 'react-icons/fa';
import {AiFillCloseCircle} from 'react-icons/ai';
import {GlobalContext} from '../../data/GlobalContext';

function GalleryContent() {
    const dbData = useContext(GlobalContext);

    const galleryImages = dbData.gallery.length > 0 ? dbData.gallery : dbData.stock;

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(galleryImages.length - 1)
            : setSlideNumber(slideNumber - 1)
    }

    // Next Image
    const nextSlide = () => {
        slideNumber + 1 === galleryImages.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }

    return (
        <Container className='galleryContent pb-5'>
            {openModal &&
                <div className='sliderWrap'>
                    <AiFillCloseCircle className='btnClose' onClick={handleCloseModal} />
                    <FaChevronCircleLeft className='btnPrev' onClick={prevSlide} />
                    <FaChevronCircleRight className='btnNext' onClick={nextSlide} />
                    <div className='fullScreenImage'>
                        <img src={galleryImages[slideNumber]} alt='images de gallery' />
                    </div>
                </div>
            }
            <div className='galleryWrap'>
                {
                    galleryImages && galleryImages.map((slide, index) => {
                        return (
                            <div
                                className='single'
                                key={index}
                                onClick={() => handleOpenModal(index)}
                            >
                                <img src={`${slide}`} alt='slide_image' />
                            </div>
                        )
                    })
                }
            </div>
        </Container>
    )
}

export default GalleryContent;