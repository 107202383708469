import React, { useContext } from 'react'
import { GlobalContext } from '../../data/GlobalContext';
// import { ButtonContent } from './boton/ButtonContent'

const VideoPromocional = ({ linkVideo, image, title, text, vimeoVideo }) => {
    const { rpdata } = useContext(GlobalContext);
    return (
        <div
            className='bgCountentVideo'
            style={{
                backgroundImage: `url("${image ? image : rpdata?.stock?.[0]}")`
            }}
        >
            <div className='countentVideoInfo '>
                <div className='info'>
                    <h2>{title}</h2>
                    <p>{text}</p>
                    {/* <ButtonContent /> */}
                </div>
                {
                    vimeoVideo ?
                        <iframe
                            title="Promotionalv"
                            className='Promotionalv'
                            src={`${linkVideo}`}
                            width="640" height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        />
                        :
                        <iframe
                            title="Promotional video"
                            className='Promotionalv'
                            id="ytplayer"
                            type="text/html"
                            src={`${linkVideo}`}
                            frameborder="0" allowfullscreen
                        />
                }
            </div>
        </div>
    )
}

export default VideoPromocional