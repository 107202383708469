import React, { useEffect, useState } from "react";
//conexion con el context global para obtener los datos de la base de datos
import axios from 'axios';
import { GlobalContext } from './components/data/GlobalContext';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// Preloader
import Preloader from './components/layouts/Preloader'
// Pages
import Home from './components/pages/Home'
import About from './components/pages/About'
import Gallery from './components/pages/Gallery'
import Services from './components/pages/Services'
import Contact from './components/pages/Contact'
import ServicesDetail from "./components/sections/servicetwo/ServicesDetail";
import Reviews from "./components/pages/Reviews";

export default () => {
  const [dbData, setdbData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const idApi = '6304e6d02db80df4064f24b0'
  const urlServer = 'https://paginasserver.herokuapp.com/api/';
  const urlApi = `${urlServer}paginas/${idApi}`;

  // global context data
  useEffect(() => {

    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios(urlApi);
      if (result.data) {
        setdbData(result.data);
        setIsLoading(false);
      }
      else {
        setdbData({});
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);
  // verificar los datos del context
  if (isLoading) return (
    <div className='d-flex justify-center align-items-center'>
      <div className='d-flex align-items-center justify-center'>
        <div className='inline-block w-8 h-8 border-4 rounded-full spinner-border animate-spin' role='status' style={{ color: 'transparent' }}>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
      <p style={{ color: 'transparent' }}>Loading...</p>
    </div>)
  else
    return (
      <GlobalContext.Provider value={dbData}>
        <Router>
          <Preloader />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/services" component={Services} />
            <Route path="/reviews" component={Reviews} />
            <Route path="/contact" component={Contact} />
            <Route path="/:id" component={ServicesDetail} />
          </Switch>
        </Router>
      </GlobalContext.Provider>
    )
}
