import React, { Fragment, useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import MetaTags from 'react-meta-tags'
import Header from '../layouts/Headertwo'
import Footer from '../layouts/Footer'
import Breadcrumbs from '../layouts/Breadcrumbs'


// import dbData from '../data/db';


const Reviews = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Fragment>
            <MetaTags>
                <title>{dbData.dbPrincipal.name} | Reviews</title>
                <meta
                    name={dbData.dbSlogan[0].slogan}
                    content={dbData.dbHome[0].text}
                />
                <link rel="icon" type="image/png" sizes="32x32" href={dbData.dbPrincipal.favicon} />
            </MetaTags>
            <Header />
            <Breadcrumbs pagename='Reviews' bgImage={dbData.gallery[21]} />

            <div className="w-4/5 mx-auto py-[100px] container">
                <h1 className="text-center pb-10">{dbData?.labels?.general?.titleReviews}</h1>
                <div className={`${dbData?.reviews?.links?.[0]}`}></div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Reviews;