import React, { Fragment, useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import MetaTags from 'react-meta-tags'
import Header from '../layouts/Headertwo'
import Footer from '../layouts/Footer'
import Content from '../sections/hometwo/Content'

// import dbData from '../data/db';
import ModalForm from '../modal/ModalForm';

const Home = (props) => {
    const dbData = useContext(GlobalContext);
    return (
        <Fragment>
            <MetaTags>
                <title>{dbData.dbPrincipal.name} | Home</title>
                <meta
                    name={dbData.dbSlogan[1].slogan}
                    content={dbData.dbHome[0].text}
                />
                <link rel="icon" type="image/png" sizes="32x32" href={dbData.dbPrincipal.favicon} />
            </MetaTags>
            <Header />
            <Content />

            <ModalForm />
            <Footer />
        </Fragment>
    );
}


export default Home;